const requireContext = require.context('./form', false, /.*\.js$/);
const modulesTemp = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true;
    }
    // if()

    return { ...modules, [name]: module };
  }, {});

export const modules = modulesTemp;

export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  projectName: null,
  projectDesc: null,
  lat: null,
  lng: null,
  address: null,
  commission: null,
  minPrice: null,
  maxPrice: null,
  developerName: null,
  youtubeUrl: null,
  brochureUrl: null,
  estimatedFinishYear: null,
  projectTypes: [],
  propertyTypes: [],
  selectedProjectType: null,
  selectedPropertyType: null,
  certificates: [],
  selectedCertificate: null,
  waterSources: [],
  selectedWaterSource: null,
  constructionStatuses: [],
  selectedConstructionStatus: null,
  loadingMap: false,
  developerPhoto: null,
  isActive: false,
  nearbyPlaces: [],
  contacts: [],
  subsidized: null,
  subsidyList: [
    { id: true, name: 'Iya, subsidi.' },
    { id: false, name: 'Tidak, bukan subsidi.' },
  ],
});

export const getters = {
  formData(state, getters) {
    const mediaFormData = getters['media/formData'];
    const regionalFormData = getters['regional/formData'];

    let currentFormData = {
      uuid: state.uuid,
      project_name: state.projectName,
      project_desc: state.projectDesc,
      lat: state.lat,
      lng: state.lng,
      address: state.address,
      min_price: state.minPrice,
      max_price: state.maxPrice,
      is_active: state.isActive,
      commission: state.commission,
      developer_name: state.developerName,
      youtube_url: state.youtubeUrl,
      brochure_url: state.brochureUrl,
      estimated_finish_year: state.estimatedFinishYear,
      village_id: state.regional.village,
      nearby_places: JSON.parse(JSON.stringify(state.nearbyPlaces)),
      contacts:
        state.selectedProjectType && state.selectedProjectType.id === 2
          ? JSON.parse(JSON.stringify(state.contacts))
          : [],
      developer_photo_uuid: state.developerPhoto ? state.developerPhoto.uuid : null,
      project_type_id: state.selectedProjectType ? state.selectedProjectType.id : null,
      property_type_id: state.selectedPropertyType ? state.selectedPropertyType.id : null,
      certificate_id: state.selectedCertificate ? state.selectedCertificate.id : null,
      water_source_id: state.selectedWaterSource ? state.selectedWaterSource.id : null,
      construction_status_id: state.selectedConstructionStatus
        ? state.selectedConstructionStatus.id
        : null,
      subsidized: state.subsidized ? state.subsidized.id : null,
    };
    return Object.assign({}, currentFormData, mediaFormData, regionalFormData);
  },
};

export const mutations = {
  SET_PROJECT_NAME(state, uuid) {
    state.projectName = uuid;
  },
  SET_PROJECT_DESC(state, uuid) {
    state.projectDesc = uuid;
  },
  SET_DEVELOPER_PHOTO(state, payload) {
    state.developerPhoto = payload;
  },
  SET_IS_ACTIVE(state, uuid) {
    state.isActive = uuid;
  },
  SET_UUID(state, uuid) {
    state.uuid = uuid;
  },
  SET_FORM_TYPE(state, formType) {
    state.formType = formType;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_LOADING_MAP(state, payload) {
    state.loadingMap = payload;
  },
  SET_LAT(state, payload) {
    state.lat = Number(payload);
  },
  SET_LNG(state, payload) {
    state.lng = Number(payload);
  },
  SET_COMMISSION(state, payload) {
    state.commission = payload;
  },
  SET_ADDRESS(state, payload) {
    state.address = payload;
  },
  SET_MIN_PRICE(state, payload) {
    state.minPrice = payload;
  },
  SET_MAX_PRICE(state, payload) {
    state.maxPrice = payload;
  },
  SET_DEVELOPER_NAME(state, payload) {
    state.developerName = payload;
  },
  SET_YOUTUBE_URL(state, payload) {
    state.youtubeUrl = payload;
  },
  SET_BROCHURE_URL(state, payload) {
    state.brochureUrl = payload;
  },
  SET_ESTIMATED_FINISH_YEAR(state, payload) {
    state.estimatedFinishYear = payload;
  },
  SET_PROJECT_TYPES(state, payload) {
    state.projectTypes = payload;
  },
  SET_PROPERTY_TYPES(state, payload) {
    state.propertyTypes = payload;
  },
  SET_SELECTED_PROJECT_TYPE(state, payload) {
    state.selectedProjectType = payload;
  },
  SET_SELECTED_PROPERTY_TYPE(state, payload) {
    state.selectedPropertyType = payload;
  },
  SET_CERTIFICATES(state, payload) {
    state.certificates = payload;
  },
  SET_SELECTED_CERTIFICATE(state, payload) {
    state.selectedCertificate = payload;
  },
  SET_WATER_SOURCES(state, payload) {
    state.waterSources = payload;
  },
  SET_SELECTED_WATER_SOURCE(state, payload) {
    state.selectedWaterSource = payload;
  },
  SET_CONSTRUCTION_STATUSES(state, payload) {
    state.constructionStatuses = payload;
  },
  SET_SELECTED_CONSTRUCTION_STATUS(state, payload) {
    state.selectedConstructionStatus = payload;
  },
  SET_SUBSIDIZED(state, payload) {
    state.subsidized = payload;
  },

  ADD_NEARBY_PLACE(state) {
    state.nearbyPlaces.push({ name: '', distance_in_km: null });
  },
  SET_NEARBY_PLACES(state, amenities) {
    let temp = [];
    amenities.forEach(function (element) {
      temp.push(element);
    });
    state.nearbyPlaces = temp;
  },
  REMOVE_NEARBY_PLACE(state, index) {
    if (state.nearbyPlaces.length > 0) {
      state.nearbyPlaces.splice(index, 1);
    }
  },
  INPUT_NEARBY_PLACE_NAME(state, { index, name }) {
    let selectedNearby = JSON.parse(JSON.stringify(state.nearbyPlaces))[index];
    selectedNearby.name = name;
    console.log('inpout nerar', selectedNearby);
    state.nearbyPlaces.splice(index, 1, selectedNearby);
  },
  INPUT_NEARBY_PLACE_DISTANCE(state, { index, name }) {
    let selectedNearby = JSON.parse(JSON.stringify(state.nearbyPlaces))[index];
    selectedNearby.distance_in_km = name;
    state.nearbyPlaces.splice(index, 1, selectedNearby);
  },

  ADD_CONTACT(state) {
    state.contacts.push({ name: '' });
  },
  SET_CONTACTS(state, contacts) {
    let temp = [];
    contacts.forEach(function (element) {
      temp.push(element);
    });
    state.contacts = temp;
  },
  REMOVE_CONTACT(state, index) {
    if (state.contacts.length > 0) {
      state.contacts.splice(index, 1);
    }
  },
  INPUT_CONTACT_PHONE(state, { index, phone }) {
    let selectedContact = JSON.parse(JSON.stringify(state.contacts))[index];
    selectedContact.phone = phone;
    state.contacts.splice(index, 1, selectedContact);
  },
};

export const actions = {
  postData: async function ({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/projects/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  applyBaseData({ commit }, baseData) {
    commit('SET_PROJECT_TYPES', baseData.project_types);
    commit('SET_PROPERTY_TYPES', baseData.property_types);
    commit('SET_CERTIFICATES', baseData.certificates);
    commit('SET_WATER_SOURCES', baseData.water_sources);
    commit('SET_CONSTRUCTION_STATUSES', baseData.construction_statuses);
  },

  async applyEditData({ commit, state, dispatch }, data) {
    await dispatch('regional/setRegionalData', data.regional_data);
    await dispatch('media/applyEditData', data);
    commit('SET_UUID', data.uuid);
    commit('SET_PROJECT_NAME', data.project_name);
    commit('SET_PROJECT_DESC', data.project_desc);
    commit('SET_LAT', data.lat);
    commit('SET_LNG', data.lng);
    commit('SET_COMMISSION', data.commission);
    commit('SET_IS_ACTIVE', data.is_active);
    commit('SET_ADDRESS', data.address);
    commit('SET_MIN_PRICE', data.min_price);
    commit('SET_MAX_PRICE', data.max_price);
    commit('SET_DEVELOPER_NAME', data.developer_name);
    commit('SET_YOUTUBE_URL', data.youtube_url);
    commit('SET_NEARBY_PLACES', data.nearby_places);
    commit('SET_CONTACTS', data.contacts);
    commit('SET_ESTIMATED_FINISH_YEAR', data.estimated_finish_year);
    if (data.subsidized && data.subsidized !== 'false') {
      commit('SET_SUBSIDIZED', state.subsidyList[0]);
    } else {
      commit('SET_SUBSIDIZED', state.subsidyList[1]);
    }

    if (data.developer_photo) {
      commit('SET_DEVELOPER_PHOTO', {
        src: data.developer_photo.photo_image,
        uuid: data.developer_photo.uuid,
      });
    }

    if (data.project_type_id) {
      const selectedProjectType = state.projectTypes.find((item) => {
        return item.id == data.project_type_id;
      });
      commit('SET_SELECTED_PROJECT_TYPE', selectedProjectType ? selectedProjectType : null);
    }
    if (data.property_type_id) {
      const selectedPropertyType = state.propertyTypes.find((item) => {
        return item.id == data.property_type_id;
      });
      commit('SET_SELECTED_PROPERTY_TYPE', selectedPropertyType ? selectedPropertyType : null);
    }
    if (data.certificate_id) {
      const selectedCertificate = state.certificates.find((item) => {
        return item.id == data.certificate_id;
      });
      commit('SET_SELECTED_CERTIFICATE', selectedCertificate ? selectedCertificate : null);
    }
    if (data.water_source_id) {
      const selectedWaterSource = state.waterSources.find((item) => {
        return item.id == data.water_source_id;
      });
      commit('SET_SELECTED_WATER_SOURCE', selectedWaterSource ? selectedWaterSource : null);
    }
    if (data.construction_status_id) {
      const selectedConstructionStatus = state.constructionStatuses.find((item) => {
        return item.id == data.construction_status_id;
      });
      commit(
        'SET_SELECTED_CONSTRUCTION_STATUS',
        selectedConstructionStatus ? selectedConstructionStatus : null,
      );
    }
  },

  async fetchBaseData({ commit, dispatch, state, rootState }) {
    try {
      commit('SET_LOADING', true);

      const form = state.formType;
      if (form === 'ADD') {
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/projects/add', {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData);
        commit('regional/SET_PROVINCES', resultData.provinces);
      } else {
        console.log('uuid', state.uuid);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/projects/edit/' + state.uuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyBaseData', resultData.base_data);
        await dispatch('applyEditData', resultData.edit_data);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async restoreInitialState({ commit, dispatch }) {
    await dispatch('regional/restoreInitialState');
    await dispatch('media/restoreInitialState');
    commit('SET_UUID', null);
    commit('SET_PROJECT_NAME', null);
    commit('SET_PROJECT_DESC', null);
    commit('SET_LAT', null);
    commit('SET_LNG', null);
    commit('SET_ADDRESS', null);
    commit('SET_COMMISSION', null);
    commit('SET_MIN_PRICE', null);
    commit('SET_MAX_PRICE', null);
    commit('SET_DEVELOPER_NAME', null);
    commit('SET_DEVELOPER_PHOTO', null);
    commit('SET_IS_ACTIVE', false);
    commit('SET_YOUTUBE_URL', null);
    commit('SET_ESTIMATED_FINISH_YEAR', null);
    commit('SET_SELECTED_PROJECT_TYPE', null);
    commit('SET_SELECTED_PROPERTY_TYPE', null);
    commit('SET_SELECTED_CERTIFICATE', null);
    commit('SET_SELECTED_WATER_SOURCE', null);
    commit('SET_SELECTED_CONSTRUCTION_STATUS', null);
    commit('SET_PROJECT_TYPES', []);
    commit('SET_PROPERTY_TYPES', []);
    commit('SET_CERTIFICATES', []);
    commit('SET_WATER_SOURCES', []);
    commit('SET_CONSTRUCTION_STATUSES', []);
    commit('SET_NEARBY_PLACES', []);
    commit('SET_CONTACTS', []);
  },
};
